import React from "react"
import LayoutCourse from "../../../../../layouts/course"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import Box from "../../../../../components/box"
import CourseFooterNext from "../../../../../components/course-footer-next"
import FeedbackHeaderBool from "../../../../../components/feedback-header-bool"
import { getCourseNavigation } from "../../../../../store/courses"
import { useCourseStore } from "../../../../../store/courses"
import Paragraph from "../../../../../components/paragraph"
import Count from "../../../../../components/count"

const Page = () => {
  const { dictAnswerIsValid } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "jahreszeiten" })
  const task = {
    courseId: "jahreszeiten",
    chapterId: "01-jahreszeitengedicht",
    taskId: "strophen-und-zeilen",
  }
  const isValid =
    dictAnswerIsValid({ ...task, dictId: "strophen" }) &&
    dictAnswerIsValid({ ...task, dictId: "zeilen" })

  return (
    <LayoutCourse
      navigation={navigation}
      footer={
        <CourseFooterNext
          title="Alles klar soweit?"
          label="Weiter"
          to="/kurse/jahreszeiten/01-jahreszeitengedicht/silben"
        />
      }
    >
      <Seo title="Hölderlin und die Jahreszeiten" />
      <Box>
        <Stack>
          <FeedbackHeaderBool
            confirmed={isValid}
            title="Das Gedicht besteht aus 2 Strophen mit jeweils 4 Versen."
            level={4}
          />
          <Paragraph>
            Viele der Gedichte, die Hölderlin im Turm verfasst hat, sind nach
            diesem Prinzip aufgebaut. Seine früheren Gedichte dagegen waren
            meist länger. Sie umfassten teils <Count>16</Count>, manchmal sogar{" "}
            <Count>30</Count> Strophen, und gelegentlich mehr als{" "}
            <Count>250</Count> Verse.
          </Paragraph>
        </Stack>
      </Box>
    </LayoutCourse>
  )
}

export default Page
